import React from 'react';

class ProductListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {
    fetch("https://jswebshop-api.scream.ee/products")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.data
          });
        },
        // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
        // чтобы не перехватывать исключения из ошибок в самих компонентах.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Ошибка : {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Загрузка...</div>;
    } else {
      return (
        <div class="container">
          <div class="row">
            {items.map(item => (
              <div class="col">
                <div class="card text-white bg-dark mb-3" key={item.id}>
                  <div class="card-header"><p class="card-text">{item.name}</p></div>
                  <div class="card-body"><p class="card-text">{item.price}€</p></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  }
}

export default ProductListComponent;